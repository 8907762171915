.responsive-logo {
  height: 60px;
}
@media (max-width: 991px) {
  .responsive-logo {
    height: 70px;
    object-fit: contain;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .responsive-logo {
    height: 40px;
    width: 150px;
  }
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .responsive-logo {
    height: 40px;
  }
}
.divider {
  position: absolute;
  /* margin-top: 1rem; */
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: block;
  top: 0;
  height: calc(100% - 2rem);
  width: 8px;
  margin-left: auto;
  background: linear-gradient(to bottom, #f26600 33%, #077430 33% 66%, #d90000 66% 100%);
  /* margin-bottom: 0.75rem; */
  border: none;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-600 {
  font-weight: 600;
}
.cursor-pointer {
  cursor: pointer;
}
.card-hover:hover {
  transform: translateY(-0.25rem);
  transition: all 200ms;
  box-shadow: 0 0.25rem 0.75rem rgb(30 34 40 / 15%);
}
.text-justify {
  text-align: justify;
}
.font-12 {
  font-size: 1.2rem;
}
.font-1 {
  font-size: 1rem;
}

.list {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.list-shortcut {
  position: fixed;
  top: 35%;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
}

.list-shortcut li a {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.7rem;
  background-color: #088738;
  border-radius: 24px 0 0 24px;
  width: 2.25rem;
  transition: all 0.2s ease-in;
  font-size: 0.833rem;
}
.list-shortcut li span {
  margin-left: 0.75rem;
  flex-shrink: 0;
  transform: translateX(100px);
  transition: all 0.2s ease-in;
  color: white;
  font-weight: 600;
}
.list-shortcut li a:hover span {
  transform: translateX(0px);
}
.list-shortcut li a:hover {
  width: auto;
}
.navbar-black {
  background-color: #fff;
}
/* .sahakari-bg {
  background: linear-gradient(to bottom, rgba(242, 102, 0, 0.5) 33%, #077430 33% 66%, #d90000 66% 100%);
} */

.sahakari-bg-orange {
  background: linear-gradient(to right, rgba(242, 102, 0, 0), rgba(242, 102, 0, 1));
  height: 124px;
  width: 100%;
  position: absolute;
}
.sahakari-bg-green {
  background: linear-gradient(to right, rgba(7, 116, 48, 0), rgba(7, 116, 48, 1));
  height: 124px;
  top: 206px;
  width: 100%;
  position: absolute;
}
.sahakari-bg-red {
  background: linear-gradient(to right, rgba(217, 0, 0, 0), rgba(217, 0, 0, 1));
  height: 124px;
  top: 330px;
  width: 100%;
  position: absolute;
}
